import { NextPage } from 'next';
import logger from 'helpers/logger';
import { ErrorPage } from 'ui/components';

const ErrorGeneric: NextPage<PageProps> = (pageProps) => {
	const { statusCode, statusMessage } = pageProps;
	logger.info('Rendering Generic Error with SSG/SSR');

	return <ErrorPage errorCode={statusCode} message={statusMessage}  />;
};

type PageProps = {
	statusCode?: number;
	statusMessage?: string;
	error?: unknown;
};

export default ErrorGeneric;

import logger from 'helpers/logger';
import { queryCmsContent } from './_api';

export interface CMSAdapterRequestInterface {
	url: string;
	host: string | string[];
	preview: boolean;
	previewData: Models.PreviewData;
	accessToken?: string;
	locale?: string;
}
export const getAllNotFoundPageDataFromCms = async ({
	url,
	host,
	preview,
	previewData,
	accessToken,
	locale,
}): Promise<Content.CMSContent> => {
	const query = `{
        #dictioary
        dictionary 
        {
        key
        values {
                culture
                value
                }
        }
        #Navigation
        navigation: navigation
        #content
        page: notFoundPage(path:"/404") {
            id
            name
            createDate
            publishDate
            documentType
            url
            properties
            template
            culture
        }
        #site
        site: sitePage(path: "/")
        {
            culture
            properties
            url
        }
    }`;

	const profiler = logger.startTimer();

	try {
		const data = await queryCmsContent({
			host,
			query,
			method: 'POST',
			preview,
			previewData,
			url,
			accessToken,
			locale,
		});
        const theme = data.page.properties.pageSettings.find(pageSetting => pageSetting.documentType === "siteTheme" );
        const retVal: Content.CMSContent = {
			pageContent:{
                page: data.page,
                siteTheme: [theme],
            },
            dictionary: data.dictionary,
            navigation: data.navigation,
            site: data.site,
		};

		return retVal;
	} finally {
		profiler.done({
			message: `get404Page`,
			level: 'debug',
		});
	}
};

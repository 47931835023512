import { getAllNotFoundPageDataFromCms } from "application/adapters/cms/404PageContentAdapter";
import { getAllDataFromCms } from "application/adapters/cms/cmsAdapter";

export interface GetCultureInterface {
	host: string | string[];
}
export interface AgendaQuery {
	host?: string;
	rootId?: number;
	pageSize?: number;
	pageNumber?: number;
	types?:string[];
}
export interface SingleAgendaQuery {
	host?: string;
	guid?: string;
}
export interface Replacement {
	name: string;
	value: string;
}
export interface DictionaryInterface {
	getValue(key: string, replacements?: Replacement[], defaultValue?: string): string;
	items: Content.DictionaryItem[];
	culture: string;
}
export interface postVideoListTagsDataProps {
	tags?: string;
	pageNumber?: number;
	pageSize?: number;
	totalPages?:number
}
export interface GetSiteProps {
	url: string;
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	accessToken?: string;
	locale?:string;
}
export interface GetCMSProps {
	url: string;
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	accessToken?: string;
	locale?:string;
	isNotFound?:boolean;
}
export interface GetSitemapInterface {
	host: string;
}
export interface SearchResultResponse {
	numberOfResults: number;
	query: string;
	searchResults: Models.SearchResult[];
}
export interface GetPageContentProps {
	url?: string;
	host?: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	accessToken?: string;
	locale?:string;
}
export interface ContentProps {
	
	url?: string;
	host?: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	accessToken?: string;
	locale?:string;
}
export interface NewsQuery {
	host?: string;
	limit?: number;
	offset?: number;
	searchableLocations?: string[],
	searchableTopics?: string[],
	preview?: boolean;
	previewData?: Models.PreviewData;
	locale? : string;
}
export interface GetNavigationOptions {
	byName?: string;
	byUrl?: string;
	currentUrl?: string;
	includeHidden?: boolean;
	includeRootItem?: boolean;
	byId?: number;
}
export interface NavigationRepositoryInterface {
	getNavigation: (options?: GetNavigationOptions) => Navigation.NavigationItem[];
	getBreadcrumb: (options?: GetNavigationOptions) => Navigation.NavigationItem[];
}
export interface GetNavigationInterface {
	url: string;
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	accessToken?: string;
	locale?:string;
}

/*
				content: await contentPromise,
				navigation: await navigationPromise,
				dictionary: await dictionaryPromise,
				site: await sitePromise,
				locale: await locale,
				childrenContent: await childrenContentPromise,
*/
export const getCMSDataProperties = async ({ url, host, preview, previewData, accessToken, locale, isNotFound}:GetCMSProps): Promise<Content.CMSContent> => {
	const data = {url, host, preview, previewData, accessToken,locale};
	
	const {pageContent, navigation, dictionary, site} = (isNotFound ? await getAllNotFoundPageDataFromCms(data) : await getAllDataFromCms(data));


	try {
		// Function to transform JSON to a more friendly structure
		// and discard all irrelevant data
		const transform = (item): Array<Navigation.NavigationItem> => {
			return item.map((child) => {
				return {
					id: child.Id,
					name: child.Title,
					url: child.Url,
					hidden: child.Hidden,
					descendants: child.Children?.length > 0 ? transform(child.Children) : [],
				};
			});
		};


		const navDataTransformed: Navigation.NavigationItem = {
			...transform([navigation])[0],
		};

		//site mapping
		const siteMappingData =
		{
			url: url,
			host: Array.isArray(host) ? host[0] : host,
			culture: site?.culture ?? null,
			title: site?.properties?.siteTitle ?? null,
			logo: site?.properties?.logo ?? null,
			logoLink: site?.properties?.logoLink ?? null,
			logoSize: site?.properties?.logoSize ?? null,
			hideServiceNavigation: site?.properties?.hideServiceNavigation ?? null,
			hideFooterNavigation: site?.properties?.hideFooterNavigation ?? null,
			hasSubsiteBar: site?.properties?.hasSubsiteBar,
			subsiteBarLogo: site?.properties?.subsiteBarLogo ?? null,
			subsiteBarLogoLink: site?.properties?.subsiteBarLogoLink ?? null,
			searchPage: site?.properties?.searchPage ?? null,
			sundhedsplatformTekstSiteSetting: site?.properties?.contactModuleHealthplatformText ?? null,
			cludoEngineId: site?.properties?.cludoEngineId ?? null,
			externalLanguagePicker: site.properties.externalLanguagePicker ?? null,
			settings: {
				tagManager: site.properties.siteSettings.find((e) => e.documentType === 'googleTagManager')?.content?.properties || null,
				maps: site.properties.siteSettings.find((e) => e.documentType === 'mapsSettings')?.content?.properties || null,
				footer: site.properties.siteSettings.find((e) => e.documentType === 'footer')?.content?.properties || null,
				socialLinks:
					site.properties.siteSettings
						.find((e) => e.documentType === 'footer')
						?.content?.properties?.socialLinks?.map((i) => ({
							url: i.content?.properties.socialLinkUrl,
							type: i.content?.properties.socialLinkType?.toLowerCase(),
						})) || null,
				internalLanguagePicker: site.properties.internalLanguagePicker || null,
			},
		}
		
		return {
			pageContent: pageContent,
			navigation:navDataTransformed,
			dictionary: dictionary,
			site: siteMappingData,
		}

	} finally {
		console.log('CmsRepository done')
	
	}
	



    
};
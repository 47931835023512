import React from 'react';
import logger from 'helpers/logger';
import { PageTypeFeature } from 'features/Modules/PageType/PageTypeFeature';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import { DXPContentPage } from 'pages/_app';

const Error404: DXPContentPage<PageProps> = (pageProps) => {
	logger.info('Rendering 404 with SSG/SSR');

	const { page } = pageProps ?? {};

	return (
		<LayoutFeature {...pageProps}>
			<PageTypeFeature {...page} />
		</LayoutFeature>
	);
};

type PageProps = {
	content: Content.PageContent;
	navigation: Navigation.NavigationItem;
	dictionary: Content.DictionaryItem[];
	site: Site.Site;
};

export default Error404;

import logger from 'helpers/logger';
import { queryCmsContent } from './_api';

export interface CMSAdapterRequestInterface {
	url: string;
	host: string | string[];
	preview: boolean;
	previewData: Models.PreviewData;
	accessToken?: string;
	locale?: string;
}
export const getAllDataFromCms = async ({
	url,
	host,
	preview,
	previewData,
	accessToken,
	locale,
}:CMSAdapterRequestInterface): Promise<Content.CMSContent> => {
	const query = `{
        #dictioary
        dictionary 
        {
        key
        values {
                culture
                value
                }
        }
        #Navigation
        navigation: navigation
        #content
        page: page {
            id
            name
            createDate
            publishDate
            documentType
            url
            properties
            template
            culture
            languagePicker
            {
                currentLanguage
                documentType
                enabled
                currentLanguage
                languages
                {
                    isoCode
                    linkType
                    name
                    url
                }
            }
        }
        #site
        site: sitePage(path: "/")
        {
            culture
            properties
            url
        }
        }`;

	const profiler = logger.startTimer();

	try {
		const data = await queryCmsContent({
			url,
			host,
			query,
			method: 'POST',
			preview,
			previewData,
			accessToken,
			locale,
		});
        const theme = data.page.properties.pageSettings.find(pageSetting => pageSetting.documentType === "siteTheme" );
        const retVal: Content.CMSContent = {
			pageContent:{
                page: data.page,
                siteTheme: [theme],
            },
            dictionary: data.dictionary,
            navigation: data.navigation,
            site: data.site,
		};

		return retVal;
	} finally {
		profiler.done({
			message: `getAllDataFromCms`,
			level: 'info',
		});
	}
};

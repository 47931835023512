import { JWT } from 'next-auth/jwt';

// Using Object Literal as Singleton Pattern
const Application: Site.Application = {
	url: null,
	locale: null,
	session: null,

	setUrl(url): URL {
		this.url = new URL(url);
		return this.url;
	},

	setLocale(tag, options): Intl.Locale {
		this.locale = new Intl.Locale(tag, options);
		return this.locale;
	},

	setSession(token): JWT {
		this.session = token;
		return this.session;
	},
};

export default Application;

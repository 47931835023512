import Error404 from 'features/Error/404';
import ErrorGeneric from 'features/Error/Generic';
import Application from 'application/entities/Application';
import logger from 'helpers/logger';
import absoluteUrl from 'next-absolute-url';
import { ApiRedirect } from 'helpers/apiRedirect';
import { getCMSDataProperties } from 'application/repositories/CmsRepository';

//DDS skal fjernes efter testet ok
// import { getDictionaryItems } from 'application/repositories/dictionaryRepository';
// import { getNavigationData } from 'application/repositories/navigationRepository';
// import { getPageContent } from 'application/repositories/pageContentRepository';
// import { getSite } from 'application/repositories/siteRepository';
// import { getChildrenContent } from 'application/repositories/childrenContentRepository';


type PageErrorProps = {
	content: Content.PageContent;
	navigation: Navigation.NavigationItem;
	dictionary: Content.DictionaryItem[];
	site: Site.Site;
	page: Content.Page;
	siteTheme: Content.SiteTheme[];
};

function Error(pageProps: { statusCode: number; statusMessage: string; error: unknown | string | null; content: PageErrorProps }): unknown {
	const { statusCode, statusMessage, error, content } = pageProps;

	return (
		<>
			{/* { <p style={{background: 'yellow'}}>{statusCode ? `An error ${statusCode} occurred on server` : 'An error occurred on client'}</p> } */}

			{statusCode === 404 ? <Error404 {...content} /> : <ErrorGeneric {...{ statusCode }} {...{ statusMessage }} {...{ error }} />}
		</>
	);
}

Error.getInitialProps = async (context) => {
	const { req, res, err, locale } = context;

	const { origin } = absoluteUrl(req);
	Application.setUrl(origin as string);
	locale ? Application.setLocale(locale) : null;

	// TODO: Find out how to set the correct protocol for subdomains.
	// When using fx. english.localhost:3001 next-absolute-url reports
	// https instead of http.
	// So for now we grab the protocol from the request hostname.

	try {
		if (req.headers.hostname === undefined) {
			logger.warn('_Error.tsx page was called without a hostname header', req.headers);
		} else {
			const { protocol } = new URL(req.headers.hostname);
			Application.url.protocol = protocol;
		}
	} catch (e) {
		logger.error('_Error.tsx page was called without a valid hostname header', req.headers, { e });
		throw Error(e);
	}

	const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
	const statusMessage = res ? res.statusMessage : err ? err.message : null;

	if (err instanceof ApiRedirect) {
		res.writeHead(err?.statusCode, {
			'Cache-Control': 'no-cache, no-store, must-revalidate',
			Expires: '0',
			Pragma: 'no-cache',
			Location: err.url || '',
		});
		res.end();
	}

	if (statusCode === 404) {
		const host = Application.url.hostname;
		const url = '404';
		
		try {
		// 	const dictionaryPromise = getDictionaryItems({ host });
		// 	const navigationPromise = getNavigationData({ url, host, locale });
		// 	const sitePromise = getSite({ url, host, locale });
		// 	const contentPromise = getPageContent({
		// 	url,
		// 	host,
		// 	locale,
		// });

		// const childrenContentPromise = getChildrenContent({
		// 	url,
		// 	host,
		// 	locale,
		// });

		const CMSDataPromise = getCMSDataProperties({ url, host, locale, isNotFound: true});

		const { pageContent, navigation, dictionary, site } = await CMSDataPromise;
		
		return {
			statusCode,
			statusMessage,
			error: err,
			content: pageContent,
			navigation: navigation,
			dictionary: dictionary,
			site: site,
			locale: locale
			};
		} catch (error) {
			logger.error(`getInitialProps() \nCannot get content for 404 error page \n${error}`);
			throw error;
		}
	} else {
		return {
			statusCode,
			statusMessage,
			error: err,
		};
	}
};

export default Error;
